import {
  ListItem as ListItemMUI,
  ListItemText as ListItemTextMUI,
  Collapse,
  ListItemIcon as ListItemIconMUI,
  ListItemProps as ListItemPropsMUI,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import cn from 'classnames';
import { ReactNode, Ref, useMemo, ComponentType, forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { KitTheme } from 'react-ui-kit-exante';

import { ReactComponent as CollapseMainMenuIcon } from '../../assets/i/CollapseMainMenu.svg';
import { ReactComponent as CollapseSubmenuIcon } from '../../assets/i/CollapseSubmenu.svg';
import { ReactComponent as ExpandMainMenuIcon } from '../../assets/i/ExpandMainMenu.svg';
import { ReactComponent as ExpandSubmenuIcon } from '../../assets/i/ExpandSubmenu.svg';
import { StyledProps } from '../../shared/helpers/types';
import { getUsername } from '../services/Cookies.service';

import MenuStyles from './Menu.module.css';
import { SmallChip } from './Menu.styled';
import { testLink, getHasActiveChildren } from './helpers';
import { MenuItemProps } from './types';

export const MenuItem = (props: MenuItemProps) => {
  const {
    text,
    itemChildren,
    children,
    open = false,
    linkTo,
    iconSrc,
    handleExpansion,
    isSubmenuItem,
    isSubmenuHeader,
    isMenuCollapsed,
    badgeCount = 0,
  } = props;
  const { hash = '', pathname = '' } = useLocation();

  const openedItemLink = pathname + hash;
  const isActiveLink = linkTo && testLink(openedItemLink, linkTo);
  const hasActiveChildren =
    itemChildren && getHasActiveChildren(openedItemLink, itemChildren);
  const isActive = isActiveLink || hasActiveChildren;

  const themeStyles: KitTheme = useTheme();
  const isMainMenuItem = !!iconSrc;

  type ListItemProps = ListItemPropsMUI & {
    component: ReactNode;
  };

  const ListItem: ComponentType<React.PropsWithChildren<ListItemProps>> =
    styled(ListItemMUI)(({ theme }: StyledProps) => ({
      color:
        open || isActive
          ? theme?.color.typo.primary
          : theme?.color.typo.secondary,
      cursor: 'pointer',
      boxShadow:
        open || isActive
          ? `inset 2px 0px 0px ${theme?.color.typo.action}`
          : 'none',
      padding: '8px 8px 8px 16px',
      backgroundColor:
        open || isActive
          ? theme?.color.input.bg.focus
          : theme?.color.input.bg.default,
      '.ListItemIcon': {
        img: {
          backgroundColor:
            open || isActive
              ? `${theme?.color.input.bg.focus} !important`
              : `${theme?.color.input.bg.default} !important`,
        },
      },
      '&:hover': {
        backgroundColor: theme?.color.input.bg.hover,
        boxShadow: `inset 2px 0px 0px ${theme?.color.typo.action}`,

        '.ListItemIcon': {
          img: {
            backgroundColor:
              open || isActive
                ? `${theme?.color.input.bg.focus} !important`
                : `${theme?.color.input.bg.hover} !important`,
          },
        },
      },
      ...(!isMainMenuItem
        ? {
            padding: '0 16px 0',
            boxShadow: 'none !important',
            background: 'none !important',
          }
        : null),
    }));

  const ListItemText = styled(ListItemTextMUI)(({ theme }: StyledProps) => ({
    color:
      open || isActive
        ? theme?.color.typo.primary
        : theme?.color.typo.secondary,
    cursor: 'pointer',
    '&:hover': {
      color: theme?.color?.typo?.promo,
    },
    paddingLeft: isMainMenuItem || isSubmenuItem ? 0 : '40px',
    '& .MuiTypography-root': {
      fontFamily: theme?.font?.main,
      fontSize: '15px',
      fontWeight: open ? 600 : 500,
      ...(!isMainMenuItem
        ? {
            fontSize: '13px',
            padding: '4px 0',
            lineHeight: '16px',
          }
        : null),
    },
    ...(!isMainMenuItem ? { margin: 0 } : null),
  }));

  const ListItemIcon = styled(ListItemIconMUI)({
    minWidth: 'auto',
    marginRight: '16px',
  });

  const CustomLink = useMemo(
    () =>
      // eslint-disable-next-line react/display-name,react/no-unstable-nested-components
      forwardRef((linkProps, ref: Ref<HTMLAnchorElement>) => {
        const userName = getUsername() || '';
        const approvalsLink =
          linkTo === '/wfe/approvals' && `/wfe/approvals?approvers=${userName}`;
        const link = approvalsLink || linkTo || '';

        return (
          <Link
            ref={ref}
            to={link}
            {...linkProps}
            style={{ textDecoration: 'none' }}
          />
        );
      }),
    [linkTo],
  );

  const rootNode = linkTo ? CustomLink : null;

  const handleClick = () =>
    handleExpansion && handleExpansion(text, itemChildren, isSubmenuHeader);

  const expandCollapseIcons = () => {
    let IconComponent;

    if (isMainMenuItem) {
      IconComponent = open ? CollapseMainMenuIcon : ExpandMainMenuIcon;
    } else {
      IconComponent = open ? CollapseSubmenuIcon : ExpandSubmenuIcon;
    }

    return (
      <IconComponent
        className={cn(MenuStyles.MenuIcon, { [MenuStyles.HiddenIcon]: !open })}
        onClick={handleClick}
      />
    );
  };

  return (
    <div className={MenuStyles.MenuItem}>
      <ListItem
        key={text}
        onClick={handleClick}
        component={rootNode as ReactNode}
      >
        {iconSrc?.default && (
          <ListItemIcon
            className={cn(MenuStyles.IconContainer, 'ListItemIcon')}
          >
            <img
              alt="active menu icon"
              src={iconSrc.active}
              className={MenuStyles.Icon}
              style={{
                backgroundColor: themeStyles.color?.bg?.primary,
              }}
            />
            <img
              alt="menu icon"
              src={iconSrc.default}
              className={cn(
                MenuStyles.Icon,
                !open ? MenuStyles.TransparentIcon : MenuStyles.HiddenIcon,
              )}
              style={{
                backgroundColor: themeStyles.color?.bg?.primary,
              }}
            />
          </ListItemIcon>
        )}
        {!isMenuCollapsed && <ListItemText primary={text} />}
        {badgeCount > 0 && <SmallChip size="small" label={badgeCount} />}
        {children && expandCollapseIcons()}
      </ListItem>
      <Collapse
        component="li"
        in={open && !isMenuCollapsed}
        timeout="auto"
        unmountOnExit
      >
        {children}
      </Collapse>
    </div>
  );
};
