import { SvgIcon, Portal } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader, Notification } from 'react-ui-kit-exante';

import { ReactComponent as ProtectIcon } from '../../../assets/i/Protect.svg';
import { ConfigContext } from '../../../config/ConfigContext';
import { isProd } from '../../../config/const';
import {
  sendMatomoLoginFailed,
  sendMatomoLoginSuccess,
} from '../../../config/matomo';
import { AuthFlow } from '../../../config/types';
import { useApi } from '../../../config/useApi';
import { useCrmsConfig } from '../../../config/useCrmsConfig';
import { useMenuConfig } from '../../../config/useMenuConfig';
import { PROVIDER_NAME } from '../../../entities/user/ProfileSettings/components/AccountSwitch/AccountSwitch';
import { fetchUserInfo } from '../../../entities/user/reducer';
import { useHomePath } from '../../../hooks/useHomePath';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useLogHandlePageLoadingTime } from '../../../hooks/useLogHandleTime';
import { isExante } from '../../../shared/helpers/routingHelpers';
import { useAppDispatch } from '../../../store/hooks';
import {
  LIGHT_THEME,
  LOCAL_STORAGE_KEY_COLOR_SCHEME,
} from '../../../theme/const';
import { TColorScheme } from '../../../theme/types';
import { useGetTokensQuery } from '../../services/AuthApi.service';
import { useAuthDBService } from '../../services/AuthDB.service';
import { saveAccessToken, saveUsername } from '../../services/Cookies.service';
import { useNodeBackService } from '../../services/NodeBack.service';
import { AuthHeader } from '../AuthHeader/AuthHeader';
import { useAuthContext } from '../AuthProvider';
import { NotificationStatus } from '../const';
import { useConnectToken } from '../hooks/useConnectToken';
import { setIsAuthorized } from '../slices/authSlice';
import { setServiceData } from '../slices/oauthSlice';
import { AuthLoginProps, ITokenInfo } from '../types';

import {
  StyledAuthLoginLoaderWrapper,
  StyledAuthLoginControls,
  StyledExternalAuthControls,
  LoginControlsTitle,
  LoginControlsTitleContent,
} from './AuthLoginMain.styled';
import { Credentials } from './components/Credentials/Credentials';
import { LoginControls } from './components/ExternalProvider/components/LoginControls/LoginControls';
import { LoginWarning } from './components/ExternalProvider/components/LoginControls/LoginControls.styled';
import { TProvider } from './components/ExternalProvider/types';

export const AuthLoginMain: FC<React.PropsWithChildren<AuthLoginProps>> = ({
  error,
  title,
}) => {
  const { search } = useLocation();
  const code = new URLSearchParams(search).get('code');
  const connectToken = new URLSearchParams(search).get('connect_token');
  const serverError = new URLSearchParams(search).get('error');
  // todo: a temporary solution, we need to figure out how to pass the url to the api directly
  const { getCheckpointBaseUrl } = useApi();
  const { checkAccessToken } = useAuthContext();
  const authDBService = useAuthDBService();
  const [bindingUserName, setBindingUserName] = useState<string>();
  const homePath = useHomePath();
  const { logHandlePageLoadingTime } = useLogHandlePageLoadingTime(homePath);
  const { bindAccount, removeConnectData, getConnectProvider } =
    useConnectToken();
  const provider = getConnectProvider() as TProvider | null;
  const providerName =
    (provider !== null && PROVIDER_NAME[provider].name) || '';
  const { initializeMenu } = useMenuConfig();
  const { initializeCrmCprmMenu } = useCrmsConfig();
  const { registerSession } = useNodeBackService();
  const { auth_flows: authFlows } = useContext(ConfigContext);
  const isLoginEnabled =
    authFlows?.length === 0 ||
    (authFlows?.length > 1 &&
      authFlows.some((flow) => flow.type === 'credentials'));

  const oauthFlows: AuthFlow[] = authFlows?.filter(
    (flow) => flow.type === 'oauth',
  );
  const { getItem } = useLocalStorage();
  const dispatch = useAppDispatch();
  const colorScheme =
    (getItem(LOCAL_STORAGE_KEY_COLOR_SCHEME) as TColorScheme) || LIGHT_THEME;

  const {
    data: accessToken,
    error: accessTokenError,
    isFetching,
  } = useGetTokensQuery(
    { code, baseUrl: getCheckpointBaseUrl() },
    {
      skip: !code,
    },
  );

  useEffect(() => {
    if (
      accessTokenError &&
      'data' in accessTokenError &&
      'error' in (accessTokenError.data as Record<'error', string>)
    ) {
      Notification[NotificationStatus.UNAUTHORIZED]({
        title: `${(accessTokenError.data as Record<'error', string>).error}\n`,
      });

      if (isProd) {
        sendMatomoLoginFailed();
      }
    }
  }, [accessTokenError]);

  useEffect(() => {
    if (!serverError) {
      return;
    }

    const errorDescription = new URLSearchParams(search).get(
      'error_description',
    );

    if (errorDescription) {
      const userName = errorDescription.split(' ').pop();
      if (userName) {
        setBindingUserName(userName);
      }
    }

    if (isProd) {
      sendMatomoLoginFailed();
    }
  }, [serverError]);

  useEffect(() => {
    (async () => {
      if (!accessToken) {
        return;
      }

      const tokenInfo = (await checkAccessToken(accessToken)) as ITokenInfo;

      if (tokenInfo) {
        const { username, userid, services } = tokenInfo as ITokenInfo;
        saveAccessToken({ ...accessToken, ...tokenInfo });
        await registerSession(accessToken?.access_token);
        await initializeMenu(accessToken?.access_token);
        initializeCrmCprmMenu(accessToken?.access_token, services);
        dispatch(setIsAuthorized(Boolean(accessToken.access_token)));
        logHandlePageLoadingTime();
        saveUsername(username);
        dispatch(fetchUserInfo({ userId: userid, authDBService }));
        bindAccount(userid);
        dispatch(setServiceData(services));

        if (isProd) {
          sendMatomoLoginSuccess();
        }
      }
    })();
  }, [accessToken, dispatch, code]);

  useEffect(() => {
    if (!code && !connectToken && !accessToken) {
      removeConnectData();
    }
  }, [code, connectToken]);

  if (isFetching || (code && !accessTokenError)) {
    return (
      <Portal>
        <StyledAuthLoginLoaderWrapper className="AuthLoginLoaderWrapper">
          <Loader isCentered size="l" />
        </StyledAuthLoginLoaderWrapper>
      </Portal>
    );
  }

  return (
    <>
      <AuthHeader title={title} />
      <StyledAuthLoginControls className="AuthLoginControls">
        {isLoginEnabled && (
          <Credentials error={error}>
            {serverError && bindingUserName && (
              <LoginWarning>
                There is no account associated with your {providerName} account.
                Login to connect accounts.
              </LoginWarning>
            )}
          </Credentials>
        )}
        {oauthFlows?.length ? (
          <LoginControlsTitle className="LoginControlsTitle">
            <LoginControlsTitleContent className="LoginControlsTitleContent">
              {serverError ? 'try again' : 'or, log in with'}
            </LoginControlsTitleContent>
          </LoginControlsTitle>
        ) : null}
        {oauthFlows?.length ? (
          <StyledExternalAuthControls>
            {oauthFlows.map((flow) => {
              if (flow.isOn && flow.type === 'oauth') {
                return (
                  <LoginControls
                    key={flow.hint}
                    hint={flow.hint}
                    icon={
                      colorScheme === LIGHT_THEME
                        ? flow.iconLight
                        : flow.iconDark
                    }
                  />
                );
              }
              return null;
            })}
          </StyledExternalAuthControls>
        ) : null}

        {isExante() && (
          <SvgIcon
            component={ProtectIcon}
            style={{ height: '48px', width: '56px' }}
            viewBox="0 0 56 48"
          />
        )}
      </StyledAuthLoginControls>
    </>
  );
};
