import { useTheme } from '@mui/material/styles';
import { FC, useContext } from 'react';
import { Button, KitTheme } from 'react-ui-kit-exante';

import { ConfigContext } from '../../../../../../../config/ConfigContext';
import { useApi } from '../../../../../../../config/useApi';
import { useLogHandlePageLoadingTime } from '../../../../../../../hooks/useLogHandleTime';
import { authWithGoogle } from '../../../../../../services/Api/helpers';
import { DEFAULT_CLIENT_ID } from '../../../../../const';
import { useConnectToken } from '../../../../../hooks/useConnectToken';
import { ILoginControls } from '../../types';

export const LoginControls: FC<React.PropsWithChildren<ILoginControls>> = ({
  icon,
  hint,
}) => {
  const { getAuthDBBaseUrl } = useApi();
  const { startStartLoginTime } = useLogHandlePageLoadingTime();
  const theme: KitTheme = useTheme();
  const { setConnectProvider } = useConnectToken();
  const { auth_data: authData } = useContext(ConfigContext);

  const handleClick = () => {
    setConnectProvider(hint);
    startStartLoginTime();
    const baseUrl = getAuthDBBaseUrl();
    authWithGoogle(`${baseUrl}/auth/authenticate`, {
      login_hint: hint,
      client_id: authData.clientId || DEFAULT_CLIENT_ID,
      scope: '',
    });
  };

  return (
    <Button
      fullWidth
      color="transparent"
      icon={<img alt={hint} src={icon} />}
      sx={{
        mt: '16px',
        mb: '24px',
        height: '48px',
        borderColor: theme?.color?.input?.border,
        '&:hover': {
          borderColor: theme?.color?.input?.border,
        },
      }}
      onClick={handleClick}
      variant="outlined"
    />
  );
};
