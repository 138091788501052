import { ReactNode, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Input, Notification } from 'react-ui-kit-exante';

import { ConfigContext } from '../../../../../config/ConfigContext';
import { isProd } from '../../../../../config/const';
import { sendMatomoLoginFailed } from '../../../../../config/matomo';
import { useApi } from '../../../../../config/useApi';
import { useLogHandlePageLoadingTime } from '../../../../../hooks/useLogHandleTime';
import { useAppDispatch } from '../../../../../store/hooks';
import { authWithLoginPassword } from '../../../../services/Api/helpers';
import { useServiceContext } from '../../../../services/ServiceProvider';
import { AuthFormValues, FormState, DEFAULT_CLIENT_ID } from '../../../const';
import { trimInputValue } from '../../../helpers';
import { setFormState, setAuthError } from '../../../slices/authSlice';
import { AuthFormControlSX } from '../../../styles';
import { TokenTypes } from '../../../types';

export const Credentials = ({
  children,
  error,
}: {
  error: string;
  children: ReactNode;
}) => {
  const {
    control,
    formState: { errors, isSubmitting },
    getValues,
  } = useFormContext();
  const { startStartLoginTime } = useLogHandlePageLoadingTime();
  const { auth_data: authData } = useContext(ConfigContext);

  const dispatch = useAppDispatch();

  const { updateCtx } = useServiceContext();

  const { getAuthDBBaseUrl } = useApi();

  const handleClick = async () => {
    startStartLoginTime();
    const { username, password } = getValues();
    const baseUrl = getAuthDBBaseUrl();

    if (username && password) {
      const response = await authWithLoginPassword(
        `${baseUrl}/auth/authenticate/login`,
        {
          client_id: authData.clientId || DEFAULT_CLIENT_ID,
          username,
          password,
        },
      );

      if (response) {
        if (response.error) {
          Notification.error({
            title: response.error,
          });

          if (isProd) {
            sendMatomoLoginFailed();
          }

          return dispatch(setAuthError(response.error));
        }

        if (updateCtx) {
          updateCtx({
            data: { ...response, username, password },
            type: response.type,
          });

          switch (response.type) {
            case TokenTypes.SMS:
              return dispatch(setFormState(FormState.SMS));
            case TokenTypes.EMAIL:
              return dispatch(setFormState(FormState.EMAIL));
            default:
              return dispatch(setFormState(FormState.GA));
          }
        }
      }
    }
    return undefined;
  };

  return (
    <>
      <Controller
        name={AuthFormValues.LOGIN}
        control={control}
        defaultValue=""
        render={({ field: { onChange, ...field } }) => {
          return (
            <Input
              autoComplete="username"
              error={Boolean(error || errors?.username?.message)}
              fullWidth
              label="Login"
              sx={AuthFormControlSX}
              inputProps={{
                'data-test-id': 'authentication-module__input--email',
              }}
              {...field}
              onChange={(event) => onChange(trimInputValue(event))}
            />
          );
        }}
      />
      <Controller
        name={AuthFormValues.PASSWORD}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Input
            autoComplete="current-password"
            error={Boolean(error || errors?.password?.message)}
            fullWidth
            label="Password"
            type={AuthFormValues.PASSWORD}
            sx={AuthFormControlSX}
            inputProps={{
              'data-test-id': 'authentication-module__input--password',
            }}
            {...field}
          />
        )}
      />
      {children}
      <Button
        loading={isSubmitting}
        data-test-id="authentication-module__button--login"
        fullWidth
        sx={AuthFormControlSX}
        onClick={handleClick}
        type="submit"
      >
        Login
      </Button>
    </>
  );
};
